import { AfterViewInit, Directive, Input } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[cTooltip]',
  host: {
    class: 'p-element',
  },
})
export class CTooltipDirective extends Tooltip implements AfterViewInit {
  // Block default and propagation on tooltip click to facilitate opening/closing via click
  @Input('cTooltipBlockClick') cTooltipBlockClick = true;

  @Input('cTooltip') set cContent(text: string) {
    this.content = text;
    this.setOption({ tooltipLabel: text });
  }

  // Activate on click as well for touch screen devices
  onInputClick(e: Event) {
    if (this.cTooltipBlockClick) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!this.active) {
      this.activate();
    } else {
      this.deactivate();
    }
  }
}
