import {
  ArtArticle,
  C4cAddress,
  C4cContact,
  C4cCustomer,
  C4cProject,
  CcfConfiguration,
  CodeNameEntity as ApiCodeNameEntity,
  Country,
  Currency,
  Dependency,
  MtlConfiguration,
  PermissionName,
  PrjMaterial,
  PrjStakeholder,
  PrjStakeholderType,
  User,
} from '@api';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { CredentialsService } from './credentials.service';
import { LoggerService } from './logger.service';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ObjectUtils } from 'primeng/utils';

export interface CodeNameEntity {
  code?: string;
  name?: string;

  translations?: { [key: string]: string };

  search?: string;
}

let me: DescriptionService;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DescriptionService {
  currencies: Currency[] = [];
  countries: Country[] = [];
  stakeholderTypeLabels: { [key: string]: string } = {};
  floorLabels: { [key: string]: string } = {};

  canViewSapNumbers: boolean;

  isDebugMode: boolean;

  optionLabel = 'label'; // same as PrimeNg fallback

  // WANING: Use me instead of this to allow the render methods to be called in contexts where this is something else.

  constructor(
    public configService: ConfigService,
    public credentialsService: CredentialsService,
    public translateService: TranslateService,
    public translateParser: TranslateParser,
  ) {
    me = this;

    this.configService.translationsConfig$.pipe(untilDestroyed(this)).subscribe(async (config) => {
      this.currencies = config.currencies || [];
      this.countries = config.countries || [];

      this.stakeholderTypeLabels = await this.getStakeholderTypeLabels(); // just in case the locale changes
      this.floorLabels = await this.getFloorLabels(); // just in case the locale changes
    });

    this.credentialsService.credentials$.pipe(untilDestroyed(this)).subscribe(async () => {
      this.canViewSapNumbers = this.credentialsService.can(PermissionName.ViewSapNumbers);

      this.stakeholderTypeLabels = await this.getStakeholderTypeLabels(); // just in case the locale changes
      this.floorLabels = await this.getFloorLabels(); // just in case the locale changes
    });

    this.isDebugMode = LoggerService.isDebugMode();
  }

  country(country: Country | string) {
    return me.name(
      typeof country === 'string' ? me.countries.find((potential) => potential.code === country) : country,
    );
  }

  currency(currency: Currency | string) {
    return me.name(
      typeof currency === 'string' ? me.currencies.find((potential) => potential.code === currency) : currency,
    );
  }

  currencySymbol(currency: Currency | string) {
    const currencyObject =
      typeof currency === 'string' ? me.currencies.find((potential) => potential.code === currency) : currency;
    const currencyCode = typeof currency === 'string' ? currency : currencyObject ? currencyObject.code : '';
    return (currencyObject ? currencyObject.symbol : '') || currencyCode;
  }

  user(user?: User) {
    if (!user) {
      return '';
    }

    const company = [];
    if (user.company_name) {
      company.push(user.company_name);
    }
    if (user.company_function) {
      company.push(user.company_function);
    }

    return (
      (user.inverted_name || '') +
      (company.length ? ` (${company.join(' - ')})` : '') +
      (me.isDebugMode && user.id ? ' | ' + user.id : '')
    );
  }

  areaManagerUser(user?: User) {
    if (!user) {
      return '';
    }

    return (
      (user.area_number || '') +
      (user.inverted_name ? `, ${user.inverted_name}` : '') +
      (me.isDebugMode && user.id ? ' | ' + user.id : '')
    );
  }

  async getStakeholderTypeLabels() {
    return {
      [PrjStakeholderType.EndCustomer]: await this.translateService.get('ui.stakeholder_type.end_customer').toPromise(),
      [PrjStakeholderType.Wholesaler]: await this.translateService.get('ui.stakeholder_type.wholesaler').toPromise(),
      [PrjStakeholderType.Installer]: await this.translateService.get('ui.stakeholder_type.installer').toPromise(),
      [PrjStakeholderType.Architect]: await this.translateService.get('ui.stakeholder_type.architect').toPromise(),
      [PrjStakeholderType.Engineer]: await this.translateService.get('ui.stakeholder_type.engineer').toPromise(),
      [PrjStakeholderType.Contractor]: await this.translateService.get('ui.stakeholder_type.contractor').toPromise(),
      [PrjStakeholderType.Inspector]: await this.translateService.get('ui.stakeholder_type.inspector').toPromise(),
      [PrjStakeholderType.Prefab]: await this.translateService.get('ui.stakeholder_type.prefab').toPromise(),
      [PrjStakeholderType.Other]: await this.translateService.get('ui.stakeholder_type.other').toPromise(),
    };
  }

  async getFloorLabels() {
    return {
      ['floor-floor']: await this.translateService.get('ui.app.@core.description-service-ts.floor-floor').toPromise(),
      ['ground-floor']: await this.translateService.get('ui.app.@core.description-service-ts.ground-floor').toPromise(),
      ['basement']: await this.translateService.get('ui.app.@core.description-service-ts.basement').toPromise(),
      ['basement-floor']: await this.translateService
        .get('ui.app.@core.description-service-ts.basement-floor')
        .toPromise(),
    };
  }

  stakeholder(stakeholder?: PrjStakeholder) {
    if (!stakeholder) {
      return '';
    }

    const parts = [];

    if (stakeholder.company_name) {
      parts.push(stakeholder.company_name);
    }
    if (stakeholder.city) {
      parts.push(stakeholder.city);
    }
    if (stakeholder.inverted_name) {
      parts.push(stakeholder.inverted_name);
    }

    if (stakeholder.type) {
      parts.push(
        me.stakeholderTypeLabels[stakeholder.type] ? me.stakeholderTypeLabels[stakeholder.type] : stakeholder.type,
      );
    }

    return parts.join(' - ') + (me.isDebugMode && stakeholder.id ? ' | ' + stakeholder.id : '');
  }

  c4cProject(project?: C4cProject) {
    if (!project) {
      return '';
    }

    const parts = [];

    if (project.number) {
      parts.push(project.number);
    }
    if (project.name) {
      parts.push(project.name);
    }
    if (project.street) {
      parts.push(project.street);
    }
    if (project.city) {
      parts.push(project.city);
    }
    if (project.zip_code) {
      parts.push(project.zip_code);
    }
    if (project.vtweg) {
      parts.push(project.vtweg);
    }
    if (project.spart && project.spart !== project.vtweg) {
      parts.push(project.spart);
    }

    return parts.join(' - ');
  }

  c4cAddress(address?: C4cAddress) {
    if (!address) {
      return '';
    }

    const parts = [];

    if (address.number) {
      parts.push(address.number);
    }
    if (address.name) {
      parts.push(address.name);
    }
    if (address.street) {
      parts.push(address.street);
    }
    if (address.city) {
      parts.push(address.city);
    }
    if (address.zip_code) {
      parts.push(address.zip_code);
    }
    if (address.phone) {
      parts.push(address.phone);
    }

    return parts.join(' - ');
  }

  c4cCustomer(customer?: C4cCustomer) {
    if (!customer) {
      return '';
    }

    const parts = [];

    if (customer.number) {
      parts.push(customer.number);
    }
    if (customer.name) {
      parts.push(customer.name);
    }
    if (customer.street) {
      parts.push(customer.street);
    }
    if (customer.city) {
      parts.push(customer.city);
    }
    if (customer.zip_code) {
      parts.push(customer.zip_code);
    }
    if (customer.phone) {
      parts.push(customer.phone);
    }
    if (customer.ktokd) {
      parts.push(customer.ktokd);
    }
    if (customer.vtweg) {
      parts.push(customer.vtweg);
    }
    if (customer.spart && customer.spart !== customer.vtweg) {
      parts.push(customer.spart);
    }

    return parts.join(' - ') + (me.isDebugMode && customer.number ? ' | ' + customer.number : '');
  }

  c4cContact(contact?: C4cContact) {
    if (!contact) {
      return '';
    }

    const parts = [];

    if (contact.inverted_name) {
      parts.push(contact.inverted_name);
    } else if (contact.name) {
      parts.push(contact.name);
    }

    if (contact.email) {
      parts.push(contact.email);
    }

    if (contact.phone) {
      parts.push(contact.phone);
    }

    if (contact.mobile) {
      parts.push(contact.mobile);
    }

    return parts.join(' - ') + (me.isDebugMode && contact.number ? ' | ' + contact.number : '');
  }

  article(article?: ArtArticle) {
    return article
      ? (article.article_number || '-') +
          ' |' +
          (article.translated_texts && article.translated_texts.short ? ' ' + article.translated_texts.short : '') +
          (me.canViewSapNumbers && article.article_number_sap && article.article_number !== article.article_number_sap
            ? ' (' + article.article_number_sap + ')'
            : '')
      : '';
  }

  articleShortText(article?: ArtArticle) {
    return article
      ? article.translated_texts && article.translated_texts.short
        ? article.translated_texts.short +
          (me.isDebugMode && article.article_number ? ' | ' + article.article_number : '')
        : article.article_number || '-'
      : '';
  }

  articleInterfaceText(article?: ArtArticle) {
    return article && article.translated_texts && article.translated_texts.interface
      ? article.translated_texts.interface +
          (me.isDebugMode && article.article_number ? ' | ' + article.article_number : '')
      : me.articleShortText(article);
  }

  codeName(entity?: CodeNameEntity | ApiCodeNameEntity | string, entities?: (CodeNameEntity | ApiCodeNameEntity)[]) {
    if (!entity) {
      return '';
    }

    if (typeof entity === 'string' && entities) {
      const potential = entities.find((potential) => potential.code === entity);
      if (potential) {
        entity = potential;
      }
    }

    if (typeof entity === 'string') {
      return entity;
    }

    const parts = [];

    if (entity.code !== undefined) {
      parts.push(entity.code || '-');
    }

    const entityWithTranslations = entity as CodeNameEntity;
    if (entityWithTranslations.translations && entityWithTranslations.translations.name) {
      if (entityWithTranslations.translations.name !== entityWithTranslations.code) {
        parts.push(entityWithTranslations.translations.name);
      }
    } else if (entity.name) {
      if (entity.name !== entity.code) {
        parts.push(entity.name);
      }
    }

    return parts.join(' | ');
  }

  name(entity?: CodeNameEntity | ApiCodeNameEntity | string, entities?: (CodeNameEntity | ApiCodeNameEntity)[]) {
    if (!entity) {
      return '';
    }

    if (typeof entity === 'string' && entities) {
      const potential = entities.find((potential) => potential.code === entity);
      if (potential) {
        entity = potential;
      }
    }

    if (typeof entity === 'string') {
      return entity;
    }

    const entityWithTranslations = entity as CodeNameEntity;
    if (entityWithTranslations.translations && entityWithTranslations.translations.name) {
      return (
        entityWithTranslations.translations.name +
        (me.isDebugMode && entityWithTranslations.code ? ' | ' + entityWithTranslations.code : '')
      );
    }

    if (entity.name) {
      return entity.name + (me.isDebugMode && entity.code ? ' | ' + entity.code : '');
    }

    if (entity.code) {
      return entity.code;
    }

    return '';
  }

  materialDescription(
    material: PrjMaterial,
    article?: ArtArticle,
    ccfConfiguration?: CcfConfiguration,
    mtlConfiguration?: MtlConfiguration,
  ) {
    if (article) {
      if (article.dummy && article.editable && [null, undefined].indexOf(material.description) === -1) {
        return material.description;
      } else if (ccfConfiguration && !ccfConfiguration.is_preset && ccfConfiguration.name) {
        return ccfConfiguration.name;
      } else if (mtlConfiguration && mtlConfiguration.name) {
        return mtlConfiguration.name;
      } else if (article.translated_texts && [null, undefined].indexOf(article.translated_texts.short) === -1) {
        return article.translated_texts.short;
      }
    } else if (ccfConfiguration && !ccfConfiguration.is_preset && ccfConfiguration.name) {
      return ccfConfiguration.name;
    } else if (mtlConfiguration && mtlConfiguration.name) {
      return mtlConfiguration.name;
    }

    return (material.description || '') + (me.isDebugMode && material.id ? ' | ' + material.id : '');
  }

  floor(floor: number) {
    if ([null, undefined].indexOf(floor) !== -1) {
      return null;
    }

    let label = me.floorLabels['floor-floor'];
    if (!floor) {
      label = me.floorLabels['ground-floor'];
    } else if (floor === -1) {
      label = me.floorLabels['basement'];
      floor = -floor;
    } else if (floor < -1) {
      label = me.floorLabels['basement-floor'];
      floor = -floor;
    }
    return me.translateParser.interpolate(label, { floor });
  }

  async dependencies(record: Dependency, prefix = '', counts: { [key: string]: number } = null) {
    let dependencies = '';

    if (record.relations) {
      const keys = Object.keys(record.relations);
      if (
        keys.length &&
        (keys.find((name) => record.relations[name]) || keys.find((name) => record.counts && record.counts[name]))
      ) {
        dependencies += ' ';
        if (!prefix) {
          dependencies += await this.translateService
            .get('ui.app.@core.description-service-ts.there-are-dependencies-that-will-be-deleted-disconnected-as-well')
            .toPromise();
        }

        dependencies += '<ul' + (!prefix ? ' class="dependencies"' : '') + '>';

        for (const name of keys) {
          if (!name) {
            return;
          }

          const count =
            record.counts && record.counts[name]
              ? record.counts[name]
              : counts && counts[prefix + name]
                ? counts[prefix + name]
                : null;

          if (count || (record.relations[name] && record.relations[name].length)) {
            dependencies += '<li class="list-style-type-relation">';
            dependencies += '<b>' + name + (count ? ' (' + count + ')' : '') + '</b>';

            dependencies += '<ul>';

            for (const relation of record.relations[name]) {
              dependencies += '<li class="list-style-type-record">';

              const parts: string[] = [];
              if (relation['article_number'] && relation['translated_texts']) {
                parts.push(this.article(relation as ArtArticle));
              }

              if ((relation['code'] || relation['name']) && relation['translations']) {
                parts.push(this.codeName(relation as CodeNameEntity));
              }

              if (!parts.length) {
                Object.keys(relation).forEach((key) => {
                  if ((key.endsWith('code') || key.indexOf('article_number') !== -1) && relation[key]) {
                    parts.push(relation[key]);
                  }
                });

                ['name', 'title', 'capacity', 'factor', 'diameter', 'diameter_in', 'diameter_out', 'box_size'].forEach(
                  (key) => {
                    if (relation[key]) {
                      parts.push(relation[key]);
                    }
                  },
                );
              }

              dependencies += parts.join(' | ');

              dependencies += await this.dependencies(
                relation,
                prefix + (prefix ? '.' : '') + name + '.',
                counts ? counts : record.counts,
              );

              dependencies += '</li>';
            }

            if (count && record.relations[name].length < count) {
              dependencies += '<li class="list-style-type-record">...</li>';
            }

            dependencies += '</ul>';

            dependencies += '</li>';
          }
        }

        dependencies += '</ul>';
      }
    }

    return dependencies;
  }

  setOptionLabels(options: any[], field?: Function, optionLabel?: string) {
    if (!options || !options.forEach) {
      return options;
    }

    if (optionLabel === undefined) {
      optionLabel = this.optionLabel;
    }

    options.forEach((option) => {
      if (option && option[optionLabel] === undefined) {
        // Remove newlines from the label otherwise the p-autoComplete forceSelection will not work because the label will not match the input value
        option[optionLabel] = String(ObjectUtils.resolveFieldData(option, field)).replace(
          new RegExp('[\r\n]', 'g'),
          '',
        );
      }
    });

    return options;
  }
}
