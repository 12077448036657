<div
  [cTooltip]="
    'ui.app.ccf.configurator.front-module-top.front-module-top-component-html.front-module-tooltip' | translate
  "
  class="module"
>
  <div class="start-top-module d-flex align-items-center">200</div>

  <ng-container *ngIf="configuration?.front_module_type !== CcfPartEndModuleType.End">
    <div
      *ngIf="configuration?.front_module_type !== CcfPartEndModuleType.D125"
      class="d-flex flex-column position-relative"
    >
      <span
        (click)="changeAdapter()"
        *ngIf="configuration?.front_module_adapter && configuration?.front_module_adapter !== module?.size"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'adapter-125': configuration?.front_module_adapter === CcfPartEndModuleAdapter.NUMBER_125,
        }"
        class="adapter text-center text-small text-truncate rotate"
      >
        {{ configuration?.front_module_adapter }}
      </span>
      <span
        *ngIf="configuration?.front_module_adapter && configuration?.front_module_adapter !== module?.size"
        [ngClass]="
          configuration?.front_module_adapter === CcfPartEndModuleAdapter.NUMBER_125
            ? 'measure-adapter-125'
            : 'measure-adapter'
        "
      >
        {{ dimensions.adapterLength }}
      </span>
    </div>

    <div class="d-flex align-items-center position-relative">
      <div
        (click)="changeAdapter()"
        *ngIf="configuration?.front_module_type !== CcfPartEndModuleType.D125"
        [ngClass]="{
          'cursor-pointer': !readonly,
          'blank text-white blink': !ccf125 && !configuration?.front_module_adapter,
        }"
        class="open-inlet text-center text-small text-truncate rotate"
      >
        <span *ngIf="!ccf125 && !configuration?.front_module_adapter" translate>
          ui.app.ccf.configurator.front-module-top.front-module-top-component-html.select
        </span>
      </div>

      <div
        (click)="changeModule()"
        *ngIf="configuration?.front_module_type == CcfPartEndModuleType.D125"
        [ngClass]="{ 'cursor-pointer': !readonly }"
        class="open-inlet inlet-d125 text-center text-small text-truncate rotate"
      >
        <span translate>ui.app.ccf.configurator.front-module-top.front-module-top-component-html.125</span>
      </div>

      <div
        (click)="changeModule()"
        [ngClass]="{ 'cursor-pointer': !readonly }"
        class="open-main d-flex align-items-center justify-content-center"
        translate
      >
        ui.app.ccf.configurator.front-module-top.front-module-top-component-html.a
      </div>

      <span *ngIf="dimensions.edgeModuleLength" class="measure">
        {{ dimensions.edgeModuleLength }}
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="configuration?.front_module_type === CcfPartEndModuleType.End">
    <div class="d-flex flex-column position-relative">
      <div (click)="changeModule()" [ngClass]="{ 'cursor-pointer': !readonly }" class="closed-main"></div>

      <span class="measure-3">
        {{ dimensions.endModuleLength }}
      </span>
    </div>
  </ng-container>
</div>
