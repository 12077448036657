<!-- Hide only on first load -->
<form class="p-d-flex p-flex-column h-100">
  <div class="p-pb-3">
    <h4 translate>ui.app.shell.news.news-component-html.news</h4>
  </div>

  <div class="p-fluid p-grid h-100 overflow-auto">
    <div>
      <div class="p-formgroup-inline">
        <div class="p-field">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              (ngModelChange)="recordsTable.first = 0"
              [(ngModel)]="recordsService.searchTerm"
              [placeholder]="'ui.app.shell.news.news-component-html.search' | translate"
              name="search"
              pInputText
              type="search"
            />
          </span>
        </div>
        <p-button
          (onClick)="recordsService.reload()"
          [label]="'ui.app.shell.news.news-component-html.reload' | translate"
          icon="pi pi-refresh"
        ></p-button>
      </div>

      <p-table
        #recordsTable
        (onLazyLoad)="recordsService.lazyLoadEvent = $event"
        [currentPageReportTemplate]="
          'ui.app.shell.news.news-component-html.showing-first-to-last-of-total-records-entries' | translate
        "
        [filterDelay]="0"
        [first]="recordsService.first"
        [lazy]="true"
        [loading]="!!(isLoading || (recordsService.loading$ | async))"
        [paginator]="true"
        [resizableColumns]="true"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [rows]="recordsService.pageSize"
        [showCurrentPageReport]="true"
        [sortField]="recordsService.sortColumn"
        [sortOrder]="recordsService.sortOrder"
        [totalRecords]="recordsService.total$ | async"
        [value]="recordsService.records$ | async"
        columnResizeMode="expand"
        dataKey="id"
        stateKeyColumnWidths="app.news.news-component-html"
        stateStorage="local"
        stickyTableHeader
        styleClass="p-datatable-striped p-datatable-gridlines p-datatable-sm"
      >
        <ng-template pTemplate="header">
          <tr>
            <th cResizableColumn class="p-text-left text-ellipsis" pSortableColumn="title">
              <span translate>ui.app.shell.news.news-component-html.title</span>
              <p-sortIcon field="title"></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <ng-template let-record pTemplate="body">
          <tr (click)="openNews(record)" [ngClass]="{ unread: !record.reads }" class="cursor-pointer">
            <td class="p-text-left text-ellipsis">{{ record.title }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="p-d-flex justify-content-between p-pt-3">
    <div>
      <div>
        <p-button
          (onClick)="cancel()"
          [label]="'ui.app.shell.news.news-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
        <p-button
          (onClick)="markAllAsRead().then()"
          [disabled]="!!isSaving"
          [label]="'ui.app.shell.news.news-component-html.mark-all-as-read' | translate"
          [loading]="!!isSaving"
          icon="pi pi-check"
        ></p-button>
      </div>
    </div>
  </div>
</form>

<p-dialog
  (keyup.enter)="showNewsDialog = false"
  (onHide)="onHideNewsDialog()"
  [(visible)]="showNewsDialog"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="header">
    <h4 class="text-ellipsis">{{ currentNews.title }}</h4>
  </ng-template>

  <ng-template pTemplate="content">
    <div [innerHTML]="currentNews.text | bypassSecurityTrustHtml" class="news-content"></div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="p-pt-3">
      <p-button
        (onClick)="showNewsDialog = false"
        [label]="'ui.app.shell.news.news-component-html.ok' | translate"
        icon="pi pi-check"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
