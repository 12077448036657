/**
 * ComfoPlan+ API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PrjStakeholderFieldType = 'company_name' | 'street' | 'zip_code' | 'city' | 'region' | 'country' | 'url' | 'first_name' | 'last_name' | 'middle_name' | 'gender' | 'phone' | 'mobile' | 'fax' | 'email' | 'company_function' | 'locale' | 'branch' | 'group' | 'ref_no' | 'requester' | 'request_date' | 'finance_conditions' | 'payment_terms';

export const PrjStakeholderFieldType = {
    CompanyName: 'company_name' as PrjStakeholderFieldType,
    Street: 'street' as PrjStakeholderFieldType,
    ZipCode: 'zip_code' as PrjStakeholderFieldType,
    City: 'city' as PrjStakeholderFieldType,
    Region: 'region' as PrjStakeholderFieldType,
    Country: 'country' as PrjStakeholderFieldType,
    Url: 'url' as PrjStakeholderFieldType,
    FirstName: 'first_name' as PrjStakeholderFieldType,
    LastName: 'last_name' as PrjStakeholderFieldType,
    MiddleName: 'middle_name' as PrjStakeholderFieldType,
    Gender: 'gender' as PrjStakeholderFieldType,
    Phone: 'phone' as PrjStakeholderFieldType,
    Mobile: 'mobile' as PrjStakeholderFieldType,
    Fax: 'fax' as PrjStakeholderFieldType,
    Email: 'email' as PrjStakeholderFieldType,
    CompanyFunction: 'company_function' as PrjStakeholderFieldType,
    Locale: 'locale' as PrjStakeholderFieldType,
    Branch: 'branch' as PrjStakeholderFieldType,
    Group: 'group' as PrjStakeholderFieldType,
    RefNo: 'ref_no' as PrjStakeholderFieldType,
    Requester: 'requester' as PrjStakeholderFieldType,
    RequestDate: 'request_date' as PrjStakeholderFieldType,
    FinanceConditions: 'finance_conditions' as PrjStakeholderFieldType,
    PaymentTerms: 'payment_terms' as PrjStakeholderFieldType
};

