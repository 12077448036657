<form *ngIf="!isLoading && (recordId !== null || duplicateRecordId)" class="p-d-flex p-flex-column h-100">
  <div class="p-pb-3">
    <h4 *ngIf="!recordId" translate>ui.app.projects.starter.starter-component-html.new-project</h4>
    <h4 *ngIf="recordId" translate>ui.app.projects.starter.starter-component-html.edit-project</h4>
  </div>

  <div class="p-fluid h-100 overflow-auto">
    <div *ngIf="canUseC4c" class="p-field">
      <label for="c4c_project" translate>ui.app.projects.starter.starter-component-html.account</label>
      <p-autoComplete
        (completeMethod)="c4cProjectsQuery$.next($event.query)"
        (ngModelChange)="c4cProjectSelectionChanged($event)"
        [(ngModel)]="c4cProjectSelection"
        [autofocus]="true"
        [dropdown]="true"
        [forceSelection]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['c4c_project'] }"
        [optionLabel]="descriptionService.optionLabel"
        [suggestions]="descriptionService.setOptionLabels(c4cProjects, descriptionService.c4cProject)"
        appendTo="body"
        dataKey="id"
        inputId="c4c_project"
        name="c4c_project"
        panelStyleClass="w-100"
        type="search"
      >
        <!-- Single select autoComplete selectedItem template does not work https://github.com/primefaces/primeng/issues/2242 -->
        <ng-template let-option pTemplate="selectedItem">
          <div class="p-d-flex p-ai-center">
            <div class="p-pb-2 text-ellipsis">
              {{ option | description: 'c4cProject' }}
            </div>
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <div class="p-d-flex p-ai-center">
            <div class="p-pb-2 text-ellipsis">
              {{ option | description: 'c4cProject' }}
            </div>
          </div>
        </ng-template>
      </p-autoComplete>
      <ng-container *ngIf="validation?.errors && validation?.errors['c4c_project']">
        <small *ngFor="let error of validation?.errors['c4c_project']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['c4c_project']">
        <small *ngFor="let warning of validation?.warnings['c4c_project']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>

    <div *ngIf="recordInput.customer_number" class="p-field warning p-p-1">
      <i class="pi pi-exclamation-triangle"></i>
      <span translate>ui.app.projects.starter.starter-component-html.the-project-is-tied-to-c4c</span>
    </div>

    <div *ngIf="isLoadingOpportunities" class="p-field">
      <app-loader *ngIf="isLoadingOpportunities"></app-loader>
    </div>
    <div *ngIf="c4cOpportunities?.length" class="p-field">
      <label for="opportunity" translate>ui.app.projects.starter.starter-component-html.opportunity</label>
      <p-dropdown
        (ngModelChange)="c4cOpportunitySelectionChanged($event)"
        [(ngModel)]="recordInput.opportunity_id"
        [filterFields]="['id', 'name']"
        [filter]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['opportunity'] }"
        [optionLabel]="descriptionService.optionLabel"
        [options]="descriptionService.setOptionLabels(c4cOpportunities, c4cOpportunityRenderer)"
        [resetFilterOnHide]="true"
        [showClear]="true"
        appendTo="body"
        inputId="opportunity"
        name="opportunity"
        optionValue="id"
        placeholder="&nbsp;"
      ></p-dropdown>
      <ng-container *ngIf="validation?.errors && validation?.errors['opportunity']">
        <small *ngFor="let error of validation?.errors['opportunity']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['opportunity']">
        <small *ngFor="let warning of validation?.warnings['opportunity']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>

    <div *ngIf="recordInput.opportunity_id" class="p-field warning p-p-1">
      <i class="pi pi-exclamation-triangle"></i>
      <span translate>ui.app.projects.starter.starter-component-html.the-stakeholders-are-tied-to-c4c</span>
    </div>

    <div class="p-field">
      <label for="type" translate>ui.app.projects.starter.starter-component-html.type</label>
      *
      <p-dropdown
        (ngModelChange)="resetErrorsAndWarnings('type')"
        [(ngModel)]="recordInput.type"
        [filter]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['type'] }"
        [optionLabel]="descriptionService.optionLabel"
        [options]="descriptionService.setOptionLabels(types, descriptionService.name)"
        [resetFilterOnHide]="true"
        [showClear]="true"
        appendTo="body"
        inputId="type"
        name="type"
        optionValue="code"
        placeholder="&nbsp;"
      ></p-dropdown>
      <ng-container *ngIf="validation?.errors && validation?.errors['type']">
        <small *ngFor="let error of validation?.errors['type']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['type']">
        <small *ngFor="let warning of validation?.warnings['type']" class="c-warning">{{ warning }}</small>
      </ng-container>
    </div>

    <div
      *ngIf="canSelectProjectAreaManager || (validation?.errors && validation?.errors['area_manager_user_id'])"
      class="p-field"
    >
      <label for="area_manager_user_id" translate>ui.app.projects.starter.starter-component-html.area-manager</label>
      *
      <p-dropdown
        (ngModelChange)="areaManagerUserSelectionChanged($event)"
        [(ngModel)]="areaManagerUserSelection"
        [filterFields]="['area_number', 'inverted_name']"
        [filter]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': validation?.errors && validation?.errors['area_manager_user_id'] }"
        [options]="areaManagerSuggestions"
        [resetFilterOnHide]="true"
        [showClear]="true"
        appendTo="body"
        dataKey="id"
        inputId="area_manager_user_id"
        name="area_manager_user_id"
        placeholder="&nbsp;"
      >
        <ng-template let-user pTemplate="selectedItem">
          <div class="p-d-flex p-ai-center">
            <div class="p-mr-1">
              <p-avatar
                *ngIf="user?.profile_picture_url"
                [image]="user?.profile_picture_url"
                class="float-left"
                shape="circle"
              ></p-avatar>
            </div>
            <div class="p-pb-2 text-ellipsis">
              {{ user | description: 'areaManagerUser' }}
            </div>
          </div>
        </ng-template>
        <ng-template let-user pTemplate="item">
          <div class="p-d-flex p-ai-center">
            <div class="p-mr-1">
              <p-avatar
                *ngIf="user?.profile_picture_url"
                [image]="user?.profile_picture_url"
                class="float-left"
                shape="circle"
              ></p-avatar>
            </div>
            <div class="p-pb-2 text-ellipsis">
              {{ user | description: 'areaManagerUser' }}
            </div>
          </div>
        </ng-template>
      </p-dropdown>
      <ng-container *ngIf="validation?.errors && validation?.errors['area_manager_user_id']">
        <small *ngFor="let error of validation?.errors['area_manager_user_id']" class="p-error">{{ error }}</small>
      </ng-container>
      <ng-container *ngIf="validation?.warnings && validation?.warnings['area_manager_user_id']">
        <small *ngFor="let warning of validation?.warnings['area_manager_user_id']" class="c-warning">
          {{ warning }}
        </small>
      </ng-container>
    </div>
  </div>

  <div class="p-d-flex justify-content-between p-pt-3">
    <div>
      <div *ngIf="validation?.message" class="p-error p-m-1">{{ validation?.message }}</div>

      <div>
        <p-button
          (onClick)="cancel().then()"
          [label]="'ui.app.projects.starter.starter-component-html.cancel' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
        <p-button
          (onClick)="save()"
          [disabled]="!!isSaving"
          [label]="'ui.app.projects.starter.starter-component-html.save' | translate"
          [loading]="!!isSaving"
          icon="pi pi-check"
        ></p-button>
      </div>
    </div>
  </div>
</form>

<app-loader *ngIf="isLoading || isSaving" [ngClass]="{ 'loader-overlay': isSaving }"></app-loader>
