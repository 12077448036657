import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { DemoComponent } from './demo.component';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  // Module is main, see app.module.ts
  Shell.childRoutes(
    [
      {
        path: 'ccf_demo',
        component: DemoComponent,
        data: { title: marker('ui.app.ccf.demo.demo-routing-module-ts.ccf-demo') },
      },
    ],
    null,
    false,
  ),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class DemoRoutingModule {}
