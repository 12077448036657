import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ConfigService, Credentials, CredentialsService, DescriptionService } from '@core';
import { AuthenticationService } from '@app/auth/authentication.service';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ArticleTransfer,
  ArticleTransfers,
  ArticleTransfersService as ApiArticleTransfersService,
  EnerventService as ApiEnerventService,
  MbuMedia,
  MbuMediaTag,
  PermissionName,
  SettingType,
} from '@api';
import { Observable } from 'rxjs';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  isLoadingLogout = false;
  isLoadingProfileRedirect = false;
  environment = environment;
  credentials: Credentials;
  canViewLogs: boolean;
  canViewTelescope: boolean;
  canManageMbus: boolean;
  canManageNotificationEmailTemplates: boolean;
  canManageExchangeRates: boolean;
  canManageNews: boolean;
  canManageLinks: boolean;
  canManageSettings: boolean;
  canManagePlanning: boolean;
  canManageTranslations: boolean;
  canManageCoreBackups: boolean;
  canManageUsers: boolean;
  canManageRoles: boolean;
  canManageUserGroups: boolean;
  canManageArticles: boolean;
  canManageArticleTransfers: boolean;
  canTriggerArticleTransfers: boolean;
  canManagePictures: boolean;
  canManagePdfSections: boolean;
  canManagePdfAdditionalTexts: boolean;
  canManagePdfAdditionalPdfs: boolean;
  canManagePdfEmailTemplates: boolean;
  canManageSapImports: boolean;
  canManageC4cImports: boolean;
  unreadNewsCount: number;

  appMenuItems: MenuItem[];
  userMenuItems: MenuItem[];

  showProfileModal: boolean;

  showNewsModal: boolean;
  newsListReloadTimestamp: number;

  appName: string;
  appVersion: string;
  headerLogo: MbuMedia;

  articleTransferTimeout: number;
  articleTransfers: ArticleTransfers;
  currentArticleTransfer: ArticleTransfer = { from_mbu_code: '...' }; // initialize with something until first check

  enerventPerformance: boolean;

  cppContentInterval: number;
  cppContentElement: HTMLElement;
  cppContentScrollHandler: EventListenerOrEventListenerObject;

  constructor(
    public authenticationService: AuthenticationService,
    public credentialsService: CredentialsService,
    public apiArticleTransfersService: ApiArticleTransfersService,
    public apiEnerventService: ApiEnerventService,
    public configService: ConfigService,
    public confirmationService: ConfirmationService,
    public translateService: TranslateService,
    public descriptionService: DescriptionService,
  ) {
    // forkJoin does not work for some reason; could use combineLatest
    [this.configService.debugConfig$, this.credentialsService.credentials$].forEach((subject: Observable<any>) => {
      subject.pipe(untilDestroyed(this)).subscribe(() => this.checkAbilities());
    });

    this.configService.settingsConfig$.pipe(untilDestroyed(this)).subscribe((settingsConfig) => {
      this.appName = settingsConfig ? (settingsConfig['APP_NAME'] as string) : null;
      this.appVersion = settingsConfig ? (settingsConfig['APP_VERSION'] as string) : null;

      this.articleTransfers = settingsConfig
        ? (settingsConfig[SettingType.ArticleTransfers] as ArticleTransfers)
        : null;

      this.enerventPerformance = settingsConfig[SettingType.EnerventPerformance] as boolean;
    });

    this.configService.mediasConfig$
      .pipe(untilDestroyed(this))
      .subscribe((mediasConfig) => (this.headerLogo = mediasConfig ? mediasConfig[MbuMediaTag.HeaderLogo] : null));
  }

  ngAfterViewInit() {
    // Keep looking for .cpp-content as it is heavily modulated by ngIf
    this.cppContentInterval = setInterval(() => {
      const cppContentElement: HTMLElement = document.querySelector('.cpp-content');
      if (cppContentElement && this.cppContentElement !== cppContentElement) {
        this.cppContentElement = cppContentElement;
        this.cppContentElement.addEventListener(
          'scroll',
          (this.cppContentScrollHandler = () => {
            const logoWrapperElement: HTMLElement = document.querySelector('.logo-wrapper');
            if (logoWrapperElement) {
              if (this.cppContentElement.scrollTop > 0) {
                if (logoWrapperElement.scrollHeight > 50) {
                  logoWrapperElement.style.width = '3rem';
                }
              } else {
                logoWrapperElement.style.width = '6.25rem';
              }
            }
          }),
        );
        if (this.cppContentElement.scrollTop !== 0) {
          this.cppContentScrollHandler(null);
        }
      }
    }, 300);
  }

  checkAbilities() {
    const debugConfig = this.configService.debugConfig$.getValue();
    this.credentials = this.credentialsService.credentials$.getValue();
    this.canViewTelescope =
      debugConfig && debugConfig.telescope && this.credentialsService.can(PermissionName.ViewTelescope);
    this.canManageMbus = this.credentialsService.can(PermissionName.ManageMbus);
    this.canManageNotificationEmailTemplates = this.credentialsService.can(
      PermissionName.ManageNotificationEmailTemplates,
    );
    this.canManageExchangeRates = this.credentialsService.can(PermissionName.ManageExchangeRates);
    this.canManageNews = this.credentialsService.can(PermissionName.ManageNews);
    this.canManageLinks = this.credentialsService.can(PermissionName.ManageLinks);
    this.canManageSettings = this.credentialsService.can(PermissionName.ManageSettings);
    this.canManagePlanning = this.credentialsService.can(PermissionName.ManagePlanning);
    this.canManageTranslations = this.credentialsService.can(PermissionName.ManageTranslations);
    this.canManageCoreBackups = this.credentialsService.can(PermissionName.ManageCoreBackups);
    this.canManageUsers = this.credentialsService.can(PermissionName.ManageUsers);
    this.canManageRoles = this.credentialsService.can(PermissionName.ManageRoles);
    this.canManageUserGroups = this.credentialsService.can(PermissionName.ManageUserGroups);
    this.canManageArticles = this.credentialsService.can(PermissionName.ManageArticles);
    this.canManageArticleTransfers = this.credentialsService.can(PermissionName.ManageArticleTransfers);
    this.canTriggerArticleTransfers = this.credentialsService.can(PermissionName.TriggerArticleTransfers);
    this.canManagePictures = this.credentialsService.can(PermissionName.ManagePictures);
    this.canManagePdfSections = this.credentialsService.can(PermissionName.ManagePdfSections);
    this.canManagePdfAdditionalTexts = this.credentialsService.can(PermissionName.ManagePdfAdditionalTexts);
    this.canManagePdfAdditionalPdfs = this.credentialsService.can(PermissionName.ManagePdfAdditionalPdfs);
    this.canManagePdfEmailTemplates = this.credentialsService.can(PermissionName.ManagePdfEmailTemplates);
    this.canManageSapImports = this.credentialsService.can(PermissionName.ManageSapImports);
    this.canManageC4cImports = this.credentialsService.can(PermissionName.ManageC4cImports);
    this.unreadNewsCount = this.credentials && this.credentials.user ? this.credentials.user.unread_news_count : null;

    this.initAppMenu().then();
    this.initUserMenu().then();

    if (this.canTriggerArticleTransfers) {
      // First time run it with a 1 seconds delay to allow other requests to run before
      this.articleTransferTimeout = setTimeout(() => this.checkArticleTransfer(), 1 * 1000);
    }
  }

  checkArticleTransfer() {
    this.clearArticleTransferTimeout();

    this.apiArticleTransfersService
      .articleTransferCurrent()
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          const previousArticleTransfer = this.currentArticleTransfer;
          this.currentArticleTransfer = res.data;

          if (
            (previousArticleTransfer ? previousArticleTransfer.id : null) !==
            (this.currentArticleTransfer ? this.currentArticleTransfer.id : null)
          ) {
            this.initAppMenu().then(); // regenerate menu
          }

          this.rescheduleArticleTransferTimeout();
        },
        () => this.rescheduleArticleTransferTimeout(),
      );
  }

  clearArticleTransferTimeout() {
    if (this.articleTransferTimeout) {
      clearTimeout(this.articleTransferTimeout);
      this.articleTransferTimeout = null;
    }
  }

  rescheduleArticleTransferTimeout() {
    this.clearArticleTransferTimeout();

    if (!this.canTriggerArticleTransfers) {
      return;
    }

    // check again in 30 seconds if current is still running or 60 otherwise
    this.articleTransferTimeout = setTimeout(
      () => this.checkArticleTransfer(),
      (this.currentArticleTransfer && !this.currentArticleTransfer.completed_at ? 30 : 60) * 1000,
    );
  }

  async initAppMenu() {
    let appMenuItems: MenuItem[] = [];

    if (this.credentials && this.credentials.user) {
      appMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.projects').toPromise(),
        icon: 'pi pi-home',
        routerLink: ['/projects'],
      });
    } else {
      appMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.login').toPromise(),
        icon: 'pi pi-key',
        routerLink: ['/login'],
      });
    }

    const appMbusMenuItems: MenuItem[] = [];

    if (this.canManageMbus) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.mbus').toPromise(),
        icon: 'pi pi-sitemap',
        routerLink: ['/management/mbus'],
      });
    }

    if (this.canManageSettings) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.settings').toPromise(),
        icon: 'pi pi-cog',
        routerLink: ['/management/settings'],
      });
    }

    if (this.canManageNotificationEmailTemplates) {
      appMbusMenuItems.push({
        label: await this.translateService
          .get('ui.app.shell.header.header-component-ts.notification-emails')
          .toPromise(),
        icon: 'pi pi-envelope',
        routerLink: ['/management/notification_email_templates'],
      });
    }

    if (this.canManageExchangeRates) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.exchange-rates').toPromise(),
        icon: 'pi pi-euro',
        routerLink: ['/management/exchange_rates'],
      });
    }

    if (this.canManageNews) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.news').toPromise(),
        icon: 'pi pi-bell',
        routerLink: ['/management/news'],
      });
    }

    if (this.canManageLinks) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.links').toPromise(),
        icon: 'pi pi-link',
        routerLink: ['/management/links'],
      });
    }

    if (this.canManagePdfSections) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.pdf-sections').toPromise(),
        icon: 'pi pi-file-pdf',
        routerLink: ['/management/pdf_sections'],
      });
    }

    if (this.canManagePdfAdditionalTexts) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.additional-texts').toPromise(),
        icon: 'pi pi-comment',
        routerLink: ['/management/pdf_additional_texts'],
      });
    }

    if (this.canManagePdfAdditionalPdfs) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.additional-pdfs').toPromise(),
        icon: 'pi pi-file-pdf',
        routerLink: ['/management/pdf_additional_pdfs'],
      });
    }

    if (this.canManagePdfEmailTemplates) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.pdf-emails').toPromise(),
        icon: 'pi pi-envelope',
        routerLink: ['/management/pdf_email_templates'],
      });
    }

    if (this.canManagePlanning) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.planning').toPromise(),
        icon: 'pi pi-folder',
        items: [
          {
            label: await this.translateService
              .get('ui.app.shell.header.header-component-ts.private-calculation-methods')
              .toPromise(),
            icon: 'pi pi-calculator',
            routerLink: ['/management/private_calculation_methods'],
          },
          {
            label: await this.translateService
              .get('ui.app.shell.header.header-component-ts.construction-types')
              .toPromise(),
            icon: 'pi pi-home',
            routerLink: ['/management/construction_types'],
          },
          {
            label: await this.translateService.get('ui.app.shell.header.header-component-ts.room-variants').toPromise(),
            icon: 'pi pi-map',
            routerLink: ['/management/room_variants'],
          },
          {
            label: await this.translateService.get('ui.app.shell.header.header-component-ts.zip-codes').toPromise(),
            icon: 'pi pi-map-marker',
            routerLink: ['/management/zip_codes'],
          },
          {
            label: await this.translateService.get('ui.app.shell.header.header-component-ts.floor-plans').toPromise(),
            icon: 'pi pi-folder',
            items: [
              {
                label: await this.translateService
                  .get('ui.app.shell.header.header-component-ts.floor-plans')
                  .toPromise(),
                icon: 'pi pi-map',
                routerLink: ['/management/floor_plans'],
              },
              {
                label: await this.translateService
                  .get('ui.app.shell.header.header-component-ts.floor-plan-rooms')
                  .toPromise(),
                icon: 'pi pi-map',
                routerLink: ['/management/floor_plan_rooms'],
              },
            ],
          },
        ],
      });
    }

    if (this.canManageC4cImports) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.c4c-imports').toPromise(),
        icon: 'pi pi-list',
        routerLink: ['/management/c4c_imports'],
      });
    }

    if (this.canManageCoreBackups) {
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.backups').toPromise(),
        icon: 'pi pi-save',
        routerLink: ['/management/core_exports'],
      });
      appMbusMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.restores').toPromise(),
        icon: 'pi pi-replay',
        routerLink: ['/management/core_imports'],
      });
    }

    if (appMbusMenuItems.length > 1) {
      const label = this.canManageMbus
        ? this.translateService.get('ui.app.shell.header.header-component-ts.mbus-settings')
        : this.translateService.get('ui.app.shell.header.header-component-ts.mbu-settings');
      appMenuItems.push({
        label: await label.toPromise(),
        icon: 'pi pi-sitemap',
        items: appMbusMenuItems,
      });
    } else if (appMbusMenuItems.length > 0) {
      appMenuItems = appMenuItems.concat(appMbusMenuItems);
    }

    const appUsersMenuItems: MenuItem[] = [];

    if (this.canManageUsers) {
      appUsersMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.users').toPromise(),
        icon: 'pi pi-users',
        routerLink: ['/management/users'],
      });
    }

    if (this.canManageRoles) {
      appUsersMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.roles').toPromise(),
        icon: 'pi pi-shield',
        routerLink: ['/management/roles'],
      });
    }

    if (this.canManageUserGroups) {
      appUsersMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.user-groups').toPromise(),
        icon: 'pi pi-users',
        routerLink: ['/management/user_groups'],
      });
    }

    if (appUsersMenuItems.length > 1) {
      const label = this.canManageRoles
        ? this.translateService.get('ui.app.shell.header.header-component-ts.users-acl')
        : this.translateService.get('ui.app.shell.header.header-component-ts.users-groups');
      appMenuItems.push({
        label: await label.toPromise(),
        icon: 'pi pi-users',
        items: appUsersMenuItems,
      });
    } else if (appUsersMenuItems.length > 0) {
      appMenuItems = appMenuItems.concat(appUsersMenuItems);
    }

    let appArticlesMenuItems: MenuItem[] = [];

    if (this.canManageArticles) {
      appArticlesMenuItems = appArticlesMenuItems.concat([
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.distributions').toPromise(),
          icon: 'pi pi-file',
          routerLink: ['/management/art/distributions'],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.material-data').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.categories').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/categories'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.articles').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/articles'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.texts').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/texts'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.prices').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/prices'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.discount-presets')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/discount_presets'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.vpe').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/vpes'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.blocked-articles')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/blocked_articles'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.replacements')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/replacements'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.qa').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.questions').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/questions'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.answers').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/answers'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.devices').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.device-groups')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/device_groups'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.device-rules')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/device_rules'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.accessories').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.accessory-groups')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/accessory_groups'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.accessory-rules')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/accessory_rules'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.tubes').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.tube-types').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/tube_types'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.tubes').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/tube_articles'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.outlets').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.outlet-groups')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/outlet_groups'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.outlet-rules')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/outlet_rules'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.ducts').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.duct-diameters')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/duct_diameter_capacities'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.duct-types').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/duct_types'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.duct-articles')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/duct_articles'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.duct-converters')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/duct_conversion_articles'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.regulators').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/duct_regulator_articles'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.splitters').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/duct_splitter_articles'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.installations').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.manifolds').toPromise(),
              icon: 'pi pi-folder',
              items: [
                {
                  label: await this.translateService
                    .get('ui.app.shell.header.header-component-ts.manifolds')
                    .toPromise(),
                  icon: 'pi pi-file-excel',
                  routerLink: ['/management/art/manifold_articles'],
                },
                {
                  label: await this.translateService
                    .get('ui.app.shell.header.header-component-ts.manifold-boxes')
                    .toPromise(),
                  icon: 'pi pi-file-excel',
                  routerLink: ['/management/art/manifold_box_articles'],
                },
              ],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.end-plates').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/end_plate_articles'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.filters').toPromise(),
              icon: 'pi pi-folder',
              items: [
                {
                  label: await this.translateService
                    .get('ui.app.shell.header.header-component-ts.filter-boxes')
                    .toPromise(),
                  icon: 'pi pi-file-excel',
                  routerLink: ['/management/art/filter_box_articles'],
                },
                {
                  label: await this.translateService.get('ui.app.shell.header.header-component-ts.filters').toPromise(),
                  icon: 'pi pi-file-excel',
                  routerLink: ['/management/art/filter_articles'],
                },
              ],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.attenuators').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/attenuator_articles'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.mounting-kits')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/mounting_kit_articles'],
            },
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.comfotherm').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/comfotherm_articles'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.ccf-configuration-presets')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/ccf_configuration_presets'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.metal-manifold-configuration-presets')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/mtl_configuration_presets'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.outside-installations')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/outside_air_articles'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.installation-defaults')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/installation_defaults'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.sets').toPromise(),
          icon: 'pi pi-folder',
          items: [
            {
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.sets').toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/sets'],
            },
            {
              label: await this.translateService
                .get('ui.app.shell.header.header-component-ts.set-materials')
                .toPromise(),
              icon: 'pi pi-file-excel',
              routerLink: ['/management/art/set_materials'],
            },
          ],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.backups').toPromise(),
          icon: 'pi pi-save',
          routerLink: ['/management/article_exports'],
        },
        {
          label: await this.translateService.get('ui.app.shell.header.header-component-ts.restores').toPromise(),
          icon: 'pi pi-replay',
          routerLink: ['/management/article_imports'],
        },
        {
          label: await this.translateService
            .get('ui.app.shell.header.header-component-ts.integrity-reports')
            .toPromise(),
          icon: 'pi pi-check-square',
          routerLink: ['/management/integrity_reports'],
        },
      ]);
    }

    if (this.canManageSapImports) {
      appArticlesMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.sap-imports').toPromise(),
        icon: 'pi pi-list',
        routerLink: ['/management/sap_imports'],
      });
    }

    let appArticleTransferMenuItems: MenuItem[] = [];

    if (this.canTriggerArticleTransfers) {
      if (this.currentArticleTransfer) {
        appArticleTransferMenuItems.push({
          label: await this.translateService
            .get(
              'ui.app.shell.header.header-component-ts.running-transfer-from-from-mbu-code',
              this.currentArticleTransfer,
            )
            .toPromise(),
          icon: 'pi pi-spin pi-spinner',
          disabled: true,
        });
      } else if (this.articleTransfers) {
        appArticleTransferMenuItems.push({
          label: await this.translateService
            .get('ui.app.shell.header.header-component-ts.trigger-transfer-from-from-mbu-code', this.articleTransfers)
            .toPromise(),
          icon: 'pi pi-play',
          command: async () => {
            this.confirmationService.confirm({
              header: await this.translateService
                .get('ui.app.shell.header.header-component-ts.trigger-article-transfer')
                .toPromise(),
              message: await this.translateService
                .get(
                  'ui.app.shell.header.header-component-ts.are-you-sure-you-want-to-trigger-article-transfer-from-from-mbu-code',
                  this.articleTransfers,
                )
                .toPromise(),
              acceptLabel: await this.translateService.get('ui.app.shell.header.header-component-ts.ok').toPromise(),
              rejectLabel: await this.translateService
                .get('ui.app.shell.header.header-component-ts.cancel')
                .toPromise(),
              acceptButtonStyleClass: 'p-button-warning',
              rejectButtonStyleClass: 'p-button-secondary',
              icon: 'pi pi-info-circle',
              accept: async () => {
                // initial placeholder until loaded from server
                this.currentArticleTransfer = {
                  from_mbu_code: this.articleTransfers.from_mbu_code,
                  locales: this.articleTransfers.locales ? this.articleTransfers.locales.join(',') : null,
                };
                await this.initAppMenu(); // regenerate menu

                this.apiArticleTransfersService
                  .articleTransferTrigger()
                  .pipe(untilDestroyed(this))
                  .subscribe(
                    (res) => {
                      this.currentArticleTransfer = res.data;
                      this.initAppMenu().then(); // regenerate menu

                      this.rescheduleArticleTransferTimeout();
                    },
                    () => {
                      this.currentArticleTransfer = null;
                      this.initAppMenu().then(); // regenerate menu
                    },
                  );
              },
            });
          },
        });
      }
    }

    if (this.canManageArticleTransfers) {
      appArticleTransferMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.transfers').toPromise(),
        icon: 'pi pi-copy',
        routerLink: ['/management/article_transfers'],
      });
    }

    if (appArticleTransferMenuItems.length === 1) {
      appArticlesMenuItems.push(appArticleTransferMenuItems[0]);
    } else if (appArticleTransferMenuItems.length > 1) {
      appArticlesMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.transfers').toPromise(),
        icon: 'pi pi-folder',
        items: appArticleTransferMenuItems,
      });
    }

    if (this.canManageArticles && this.enerventPerformance) {
      appArticlesMenuItems.push({
        label: await this.translateService
          .get('ui.app.shell.header.header-component-ts.clear-enervent-performance')
          .toPromise(),
        icon: 'pi pi-trash',
        command: async () => {
          this.confirmationService.confirm({
            header: await this.translateService
              .get('ui.app.shell.header.header-component-ts.clear-enervent-performance')
              .toPromise(),
            message: await this.translateService
              .get('ui.app.shell.header.header-component-ts.are-you-sure-you-want-to-clear-enervent-performance')
              .toPromise(),
            acceptLabel: await this.translateService.get('ui.app.shell.header.header-component-ts.ok').toPromise(),
            rejectLabel: await this.translateService.get('ui.app.shell.header.header-component-ts.cancel').toPromise(),
            acceptButtonStyleClass: 'p-button-warning',
            rejectButtonStyleClass: 'p-button-secondary',
            icon: 'pi pi-info-circle',
            accept: async () => {
              this.apiEnerventService.deletePerformance().pipe(untilDestroyed(this)).subscribe();
            },
          });
        },
      });
    }

    if (this.canManagePictures) {
      appArticlesMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.media').toPromise(),
        icon: 'pi pi-images',
        routerLink: ['/management/medias'],
      });
    }

    if (appArticlesMenuItems.length) {
      appMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.articles').toPromise(),
        icon: 'pi pi-folder',
        items: appArticlesMenuItems,
      });
    }

    if (this.canManageTranslations) {
      appMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.translations').toPromise(),
        icon: 'pi pi-book',
        routerLink: ['/management/translations'],
      });
    }

    const appLogsMenuItems: MenuItem[] = [];

    if (this.canViewTelescope) {
      appLogsMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.telescope').toPromise(),
        icon: 'pi pi-briefcase',
        url: environment.serverUrl + '/telescope',
        target: '_blank',
      });
    }

    if (appLogsMenuItems.length > 1) {
      appMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.logs').toPromise(),
        icon: 'pi pi-briefcase',
        items: appLogsMenuItems,
      });
    } else if (appLogsMenuItems.length > 0) {
      appMenuItems = appMenuItems.concat(appLogsMenuItems);
    }

    const otherLinks: MenuItem[] = [];
    if (this.credentials && this.credentials.user && this.credentials.user.links) {
      this.credentials.user.links.forEach((link) => {
        const menuItem = {
          label: link.title,
          icon: 'pi pi-link',
          url: link.url,
          target: link.blank ? '_blank' : null,
        };

        if (link.menu) {
          appMenuItems.push(menuItem);
        } else {
          otherLinks.push(menuItem);
        }
      });
    }
    if (otherLinks.length) {
      appMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.links').toPromise(),
        icon: 'pi pi-link',
        items: otherLinks,
      });
    }

    this.appMenuItems = appMenuItems;
  }

  async initUserMenu() {
    const userMenuItems: MenuItem[] = [];

    if (this.credentials) {
      if (this.credentials.user) {
        if (this.credentials.user.inverted_name) {
          userMenuItems.push({
            label: this.credentials.user.inverted_name,
            styleClass: 'p-text-bold',
          });
          userMenuItems.push({
            separator: true,
          });
        }

        userMenuItems.push({
          label:
            (await this.translateService.get('ui.app.shell.header.header-component-ts.news').toPromise()) +
            // Ugly hack because MenuItem.badge only applies to premium templates
            (this.credentials.user.unread_news_count
              ? `<span class="p-ml-1 p-badge p-component p-badge-no-gutter">${this.unreadNewsCount}</span>`
              : ''),
          escape: false,
          icon: 'pi pi-bell',
          disabled: this.isLoadingLogout,
          command: () => {
            this.newsListReloadTimestamp = new Date().getTime(); // trigger a news list reload
            this.showNewsModal = true;
          },
        });

        if (this.credentials.user.id) {
          userMenuItems.push({
            label: await this.translateService.get('ui.app.shell.header.header-component-ts.profile').toPromise(),
            icon: 'pi pi-user',
            disabled: this.isLoadingLogout,
            command: () => (this.showProfileModal = true),
          });
        }

        if (this.credentials.user.has_cross_login) {
          userMenuItems.push({
            label: await this.translateService.get('ui.app.shell.header.header-component-ts.my-zehnder').toPromise(),
            icon: this.isLoadingProfileRedirect ? 'pi pi-spin pi-spinner' : 'pi pi-directions',
            disabled: this.isLoadingProfileRedirect || this.isLoadingLogout,
            command: () => this.goToProfile(),
          });
        }

        if (this.credentials.user.mbus && this.credentials.user.mbus.length) {
          const mbuMenuItems: MenuItem[] = [];
          this.credentials.user.mbus.forEach((mbu) => {
            if (mbu.url) {
              mbuMenuItems.push({
                label: this.descriptionService.codeName(mbu),
                icon: 'pi pi-external-link',
                url: mbu.url,
                target: '_blank',
              });
            }
          });
          if (mbuMenuItems.length) {
            userMenuItems.push({
              label: await this.translateService.get('ui.app.shell.header.header-component-ts.mbus').toPromise(),
              icon: 'pi pi-sitemap',
              items: mbuMenuItems,
              styleClass: 'p-submenu-right',
            });
          }
        }

        userMenuItems.push({
          separator: true,
        });
      }

      userMenuItems.push({
        label: await this.translateService.get('ui.app.shell.header.header-component-ts.logout').toPromise(),
        icon: this.isLoadingLogout ? 'pi pi-spin pi-spinner' : 'pi pi-sign-out',
        disabled: this.isLoadingLogout,
        command: () => this.logout(),
      });
    }

    this.userMenuItems = userMenuItems;
  }

  goToProfile() {
    this.isLoadingProfileRedirect = true;
    this.initUserMenu().then();

    this.authenticationService
      .profileRedirect()
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.isLoadingProfileRedirect = false;
          this.initUserMenu().then();

          if (res.data && res.data.redirect_uri) {
            window.open(res.data.redirect_uri);
          }
        },
        () => {
          this.isLoadingProfileRedirect = false;
          this.initUserMenu().then();
        },
      );
  }

  logout() {
    this.isLoadingLogout = true;
    this.initUserMenu().then();

    const handler = () => {
      this.isLoadingLogout = false;
      // this.initUserMenu().then(); // no need any more
    };

    this.authenticationService.logout().pipe(untilDestroyed(this)).subscribe(handler, handler);
  }

  ngOnDestroy(): void {
    this.clearArticleTransferTimeout();

    if (this.cppContentInterval) {
      clearInterval(this.cppContentInterval);
    }

    if (this.cppContentElement && this.cppContentScrollHandler) {
      this.cppContentElement.removeEventListener('scroll', this.cppContentScrollHandler);
      this.cppContentScrollHandler = null;
    }
  }
}
