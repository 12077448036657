<ng-template #headerTemplate>
  <!-- Using styles to avoid global classes -->
  <img
    [src]="'/assets/navigation-icon.svg?v=' + navigationService.environment.version"
    style="width: 30px; height: 30px"
  />
  <div class="p-d-flex p-flex-column" style="flex-shrink: 2; max-width: 10rem">
    <span class="title text-ellipsis">
      {{ 'ui.app.@shared.navigation.navigation-component-html.current-step' | translate }}
    </span>
    <span class="text-ellipsis">{{ navigationService.title }}</span>
  </div>
  <div>
    <i *ngIf="!navigationService.visible" class="pi pi-caret-right p-pl-4 icons-lg"></i>
    <i *ngIf="navigationService.visible" class="pi pi-caret-down p-pl-4 icons-lg"></i>
  </div>
  <div
    (click)="navigationService.pinned = !navigationService.pinned; $event.stopPropagation()"
    *ngIf="navigationService.visible"
    class="icons"
  >
    <i *ngIf="!navigationService.pinned" class="pi pi-lock-open p-pl-4 icons-md"></i>
    <i *ngIf="navigationService.pinned" class="pi pi-lock p-pl-4 icons-md"></i>
  </div>
</ng-template>

<ng-template #itemsTemplate>
  <div *ngFor="let item of navigationService.items; last as isLastItem" class="root">
    <a
      (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
      [ngClass]="{
        current:
          navigationService.state?.code === item.code &&
          !navigationService.state?.buildingId &&
          !navigationService.state?.apartmentId,
        disabled: !item.url?.length,
        blocked: item.blocked,
        'cursor-not-allowed': !item.available,
      }"
      [queryParams]="item.external ? { url: item.url?.join('/') } : null"
      [routerLink]="item.available ? (!item.external ? item.url : ['/redirect']) : null"
      class="item disabled"
    >
      <span [ngClass]="{ available: item.available, completed: item.partiallyCompleted }" class="circle"></span>
      <span class="item-name text-ellipsis">{{ item.name }}</span>
      <i *ngIf="item.tooltip" [cTooltip]="item.tooltip" class="pi pi-info-circle p-ml-1"></i>
    </a>

    <div *ngIf="!isLastItem" class="wrapper-line">
      <span [ngClass]="{ completed: item.partiallyCompleted }" class="line"></span>
    </div>

    <div *ngFor="let building of item.buildings">
      <a
        (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
        [ngClass]="{
          current:
            navigationService.state?.code === item.code &&
            navigationService.state?.buildingId === building.id &&
            !navigationService.state?.apartmentId,
          disabled: !building.url?.length,
          blocked: building.blocked,
          'cursor-not-allowed': !building.available,
        }"
        [queryParams]="building.external ? { url: building.url?.join('/') } : null"
        [routerLink]="building.available ? (!building.external ? building.url : ['/redirect']) : null"
        [state]="{ building_id: building.id }"
        class="item"
      >
        <!-- Instead of refactoring the CSS for links, we just stop the event -->
        <span
          (click)="
            $event.preventDefault();
            $event.stopPropagation();
            navigationService.collapseNavigationItem((building.collapsed = !building.collapsed), building.id)
          "
          [ngClass]="{ available: building.available, completed: building.partiallyCompleted }"
          class="circle"
        >
          <ng-container *ngIf="building.collapsed">+</ng-container>
          <!-- Using a specific width minus sign  -->
          <ng-container *ngIf="!building.collapsed">&#8722;</ng-container>
        </span>
        <span class="item-name text-ellipsis">{{ building.name }}</span>
        <i *ngIf="building.tooltip" [cTooltip]="building.tooltip" class="pi pi-info-circle p-ml-1"></i>
      </a>

      <div *ngIf="building.collapsed || !building.apartments?.length" class="wrapper-line">
        <span
          [ngClass]="{
            completed:
              (!building.collapsed && building.partiallyCompleted) || (building.collapsed && building.completed),
          }"
          class="line"
        ></span>
      </div>

      <div
        *ngIf="!building.collapsed && (building.apartments?.length || building.stepsBeforeApartments?.length)"
        class="nested"
      >
        <div class="wrapper-line-right">
          <span [ngClass]="{ completed: building.partiallyCompleted }" class="line-right"></span>
        </div>

        <div *ngFor="let step of building.stepsBeforeApartments; last as isLastStep">
          <a
            (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
            [ngClass]="{
              current:
                navigationService.state?.code === step.code &&
                navigationService.state?.buildingId === building.id &&
                !navigationService.state?.apartmentId,
              disabled: !step.url?.length,
              blocked: step.blocked,
              'cursor-not-allowed': !step.available,
            }"
            [queryParams]="step.external ? { url: step.url?.join('/') } : null"
            [routerLink]="step.available ? (!step.external ? step.url : ['/redirect']) : null"
            [state]="{ building_id: building.id }"
            class="item"
          >
            <span [ngClass]="{ available: step.available, completed: step.completed }" class="circle"></span>
            <span class="item-name text-ellipsis">{{ step.name }}</span>
            <i *ngIf="step.tooltip" [cTooltip]="step.tooltip" class="pi pi-info-circle p-ml-1"></i>
          </a>

          <div *ngIf="!isLastStep" class="wrapper-line">
            <span [ngClass]="{ completed: step.completed }" class="line"></span>
          </div>
        </div>

        <ng-container *ngIf="building.stepsBeforeApartments?.length">
          <div *ngIf="building.apartments?.length" class="wrapper-line">
            <span
              [ngClass]="{
                completed: building.stepsBeforeApartments[building.stepsBeforeApartments?.length - 1]?.completed,
              }"
              class="line"
            ></span>
          </div>

          <div *ngIf="!building.apartments?.length" class="wrapper-line-left">
            <span
              [ngClass]="{
                completed: building.stepsBeforeApartments[building.stepsBeforeApartments?.length - 1]?.completed,
              }"
              class="line-left"
            ></span>
          </div>
        </ng-container>

        <div *ngFor="let apartment of building.apartments; last as isLastApartment">
          <a
            (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
            [ngClass]="{
              current:
                navigationService.state?.code === item.code &&
                navigationService.state?.buildingId === building.id &&
                navigationService.state?.apartmentId === apartment.id,
              disabled: !apartment.url?.length,
              blocked: apartment.blocked,
              'cursor-not-allowed': !apartment.available,
            }"
            [queryParams]="apartment.external ? { url: apartment.url?.join('/') } : null"
            [routerLink]="apartment.available ? (!apartment.external ? apartment.url : ['/redirect']) : null"
            [state]="{ building_id: building.id, apartment_id: apartment.id }"
            class="item"
          >
            <!-- Instead of refactoring the CSS for links, we just stop the event -->
            <span
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                navigationService.collapseNavigationItem(
                  (apartment.collapsed = !apartment.collapsed),
                  building.id,
                  apartment.id
                );
                apartment.zones?.length === 1
                  ? navigationService.collapseNavigationItem(
                      (apartment.zones[0].collapsed = apartment.collapsed),
                      building.id,
                      apartment.id,
                      apartment.zones[0].zone
                    )
                  : null
              "
              [ngClass]="{ available: apartment.available, completed: apartment.partiallyCompleted }"
              class="circle"
            >
              <ng-container *ngIf="apartment.collapsed">+</ng-container>
              <!-- Using a specific width minus sign  -->
              <ng-container *ngIf="!apartment.collapsed">&#8722;</ng-container>
            </span>
            <span class="item-name text-ellipsis">{{ apartment.name }}</span>
            <i *ngIf="apartment.tooltip" [cTooltip]="apartment.tooltip" class="pi pi-info-circle p-ml-1"></i>
          </a>

          <ng-container *ngIf="apartment.collapsed || !apartment.zones?.length">
            <div *ngIf="!isLastApartment || building.stepsAfterApartments?.length" class="wrapper-line">
              <span
                [ngClass]="{
                  completed:
                    (!apartment.collapsed && apartment.partiallyCompleted) ||
                    (apartment.collapsed && apartment.completed),
                }"
                class="line"
              ></span>
            </div>

            <div *ngIf="isLastApartment && !building.stepsAfterApartments?.length" class="wrapper-line-left">
              <span
                [ngClass]="{
                  completed:
                    (!apartment.collapsed && apartment.partiallyCompleted) ||
                    (apartment.collapsed && apartment.completed),
                }"
                class="line-left"
              ></span>
            </div>
          </ng-container>

          <div
            *ngIf="!apartment.collapsed && apartment.zones?.length"
            [ngClass]="{ nested: apartment.zones?.length !== 1 }"
          >
            <div *ngIf="apartment.zones?.length !== 1" class="wrapper-line-right">
              <span [ngClass]="{ completed: apartment.partiallyCompleted }" class="line-right"></span>
            </div>

            <div *ngFor="let zone of apartment.zones; last as isLastZone">
              <a
                (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
                *ngIf="apartment.zones?.length > 1"
                [ngClass]="{
                  disabled: !zone.url?.length,
                  blocked: zone.blocked,
                  'cursor-not-allowed': !zone.available,
                }"
                [queryParams]="zone.external ? { url: zone.url?.join('/') } : null"
                [routerLink]="zone.available ? (!zone.external ? zone.url : ['/redirect']) : null"
                [state]="{ building_id: building.id, apartment_id: apartment.id, zone: zone.zone }"
                class="item"
              >
                <!-- Instead of refactoring the CSS for links, we just stop the event -->
                <span
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    navigationService.collapseNavigationItem(
                      (zone.collapsed = !zone.collapsed),
                      building.id,
                      apartment.id,
                      zone.zone
                    )
                  "
                  [ngClass]="{ available: zone.available, completed: zone.partiallyCompleted }"
                  class="circle"
                >
                  <ng-container *ngIf="zone.collapsed">+</ng-container>
                  <!-- Using a specific width minus sign  -->
                  <ng-container *ngIf="!zone.collapsed">&#8722;</ng-container>
                </span>
                <span class="item-name text-ellipsis">{{ zone.name }}</span>
                <i *ngIf="zone.tooltip" [cTooltip]="zone.tooltip" class="pi pi-info-circle p-ml-1"></i>
              </a>

              <div *ngIf="(zone.collapsed && !isLastZone) || !zone.steps?.length" class="wrapper-line">
                <span
                  [ngClass]="{
                    completed: (!zone.collapsed && zone.partiallyCompleted) || (zone.collapsed && zone.completed),
                  }"
                  class="line"
                ></span>
              </div>

              <div *ngIf="!zone.collapsed && zone.steps?.length" class="nested">
                <div class="wrapper-line-right">
                  <span [ngClass]="{ completed: zone.partiallyCompleted }" class="line-right"></span>
                </div>

                <div *ngFor="let step of zone.steps; last as isLastStep">
                  <a
                    (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
                    [ngClass]="{
                      current:
                        navigationService.state?.code === step.code &&
                        navigationService.state?.apartmentId === apartment.id &&
                        (navigationService.state?.zone || 0) === (zone.zone || 0),
                      disabled: !step.url?.length,
                      blocked: step.blocked,
                      'cursor-not-allowed': !step.available,
                    }"
                    [queryParams]="step.external ? { url: step.url?.join('/') } : null"
                    [routerLink]="step.available ? (!step.external ? step.url : ['/redirect']) : null"
                    [state]="{ building_id: building.id, apartment_id: apartment.id, zone: zone.zone }"
                    class="item"
                  >
                    <span [ngClass]="{ available: step.available, completed: step.completed }" class="circle"></span>
                    <span class="item-name text-ellipsis">{{ step.name }}</span>
                    <i *ngIf="step.tooltip" [cTooltip]="step.tooltip" class="pi pi-info-circle p-ml-1"></i>
                  </a>

                  <div *ngIf="!isLastStep" class="wrapper-line">
                    <span [ngClass]="{ completed: step.completed }" class="line"></span>
                  </div>
                </div>

                <div
                  *ngIf="
                    !isLastZone ||
                    (apartment.zones?.length === 1 && (!isLastApartment || building.stepsAfterApartments?.length))
                  "
                  class="wrapper-line-left"
                >
                  <span
                    [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }"
                    class="line-left"
                  ></span>
                </div>

                <div
                  *ngIf="
                    isLastZone &&
                    apartment.zones?.length !== 1 &&
                    (!isLastApartment || building.stepsAfterApartments?.length)
                  "
                  class="wrapper-line-left-2"
                >
                  <span
                    [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }"
                    class="line-left-2"
                  ></span>
                </div>

                <ng-container *ngIf="isLastApartment && !building.stepsAfterApartments?.length">
                  <div *ngIf="apartment.zones?.length === 1" class="wrapper-line-left-2">
                    <span
                      [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }"
                      class="line-left-2"
                    ></span>
                  </div>

                  <ng-container *ngIf="isLastZone && apartment.zones?.length !== 1">
                    <div *ngIf="zone.steps" class="wrapper-line-left-3">
                      <span
                        [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }"
                        class="line-left-3"
                      ></span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div
                *ngIf="!zone.collapsed && isLastZone && apartment.zones?.length !== 1 && !zone.steps"
                class="wrapper-line-left"
              >
                <span [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }" class="line-left"></span>
              </div>

              <ng-container *ngIf="zone.collapsed && isLastZone">
                <div
                  *ngIf="apartment.zones?.length !== 1 && (!isLastApartment || building.stepsAfterApartments?.length)"
                  class="wrapper-line-left"
                >
                  <span
                    [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }"
                    class="line-left"
                  ></span>
                </div>

                <ng-container *ngIf="isLastApartment && !building.stepsAfterApartments?.length">
                  <div *ngIf="apartment.zones?.length === 1" class="wrapper-line-left">
                    <span
                      [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }"
                      class="line-left"
                    ></span>
                  </div>

                  <div *ngIf="apartment.zones?.length !== 1" class="wrapper-line-left-2">
                    <span
                      [ngClass]="{ completed: zone.steps[zone.steps?.length - 1]?.completed }"
                      class="line-left-2"
                    ></span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngFor="let step of building.stepsAfterApartments; last as isLastStep">
          <a
            (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
            [ngClass]="{
              current:
                navigationService.state?.code === step.code &&
                navigationService.state?.buildingId === building.id &&
                !navigationService.state?.apartmentId,
              disabled: !step.url?.length,
              blocked: step.blocked,
              'cursor-not-allowed': !step.available,
            }"
            [queryParams]="step.external ? { url: step.url?.join('/') } : null"
            [routerLink]="step.available ? (!step.external ? step.url : ['/redirect']) : null"
            [state]="{ building_id: building.id }"
            class="item"
          >
            <span [ngClass]="{ available: step.available, completed: step.completed }" class="circle"></span>
            <span class="item-name text-ellipsis">{{ step.name }}</span>
            <i *ngIf="step.tooltip" [cTooltip]="step.tooltip" class="pi pi-info-circle p-ml-1"></i>
          </a>

          <div *ngIf="!isLastStep" class="wrapper-line">
            <span [ngClass]="{ completed: step.completed }" class="line"></span>
          </div>
        </div>

        <div *ngIf="building.stepsAfterApartments?.length" class="wrapper-line-left">
          <span
            [ngClass]="{
              completed: building.stepsAfterApartments[building.stepsAfterApartments?.length - 1]?.completed,
            }"
            class="line-left"
          ></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div
  (click)="navigationService.visible = !navigationService.visible"
  *ngIf="header && navigationService.state"
  [ngClass]="{ 'pinned-header': navigationService.pinned && navigationService.visible }"
  class="p-d-flex p-ai-center cursor-pointer"
  style="flex: 1 1 100%; max-height: 40px"
>
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
</div>

<p-sidebar
  *ngIf="sidebar && !navigationService.pinned"
  [(visible)]="navigationService.visible"
  [blockScroll]="true"
  [closeOnEscape]="true"
  [dismissible]="true"
  position="right"
  styleClass="p-sidebar-sm sidebar-hide-header-and-footer sidebar-content-h-100"
>
  <div class="p-d-flex p-flex-column h-100">
    <div
      (click)="!navigationService.pinned ? (navigationService.visible = false) : null"
      class="p-d-flex p-ai-center cursor-pointer p-pb-2 sidebar-header"
    >
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>

    <div class="content p-fluid h-100 p-pt-3 overflow-auto">
      <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
    </div>
  </div>

  <app-loader *ngIf="navigationService.isLoading" class="loader-overlay"></app-loader>
</p-sidebar>

<ng-container *ngIf="!sidebar && navigationService.pinned && navigationService.visible">
  <!-- No height or overflow as this is pinned -->
  <div class="pinned-items p-d-flex p-flex-column">
    <div class="content p-fluid">
      <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
    </div>

    <app-loader *ngIf="navigationService.isLoading" class="loader-overlay"></app-loader>
  </div>
</ng-container>
