import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { LazyLoadEvent } from 'primeng/api';
import { FilterOperator, Filters, Paginator } from '@api';
import { ActivatedRoute, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';

export type SortDirection = 'asc' | 'desc' | '';

export interface RecordsList<T> extends Paginator {
  data?: T[];
}

export interface State {
  searchTerm?: string;
  withTrashed?: boolean;
  filters?: string;
  page?: number;
  pageSize?: number;
  sortColumn?: string;
  sortDirection?: SortDirection;
  fields?: string;
  relations?: string;
}

let route: ActivatedRoute;

export function setRoute(value: ActivatedRoute) {
  route = value;
}

let router: Router;

export function setRouter(value: Router) {
  router = value;
}

let platformId: string;

export function setPlatformId(value: string) {
  platformId = value;
}

@UntilDestroy()
export class RecordsService<T> {
  // while PrimeNg Table defaults to session, all the tables in the app use local; so defaulting this to local
  protected stateStorage: 'session' | 'local' = 'local';
  protected stateStorageKey: string;

  protected _load$ = new Subject<void>();

  protected _state: State = {}; // start empty
  protected _queryParams = true; // append state into query params
  protected _queryParamsPrefix: string; // prefix query params names
  protected _queryParamsReplaceUrl = true; // add or replace current history state
  protected _queryParamsFields = false; // include the fields in the query params
  protected _queryParamsRelations = false; // include the relations in the query params
  protected _queryParamsAreReset = false;

  constructor(stateStorageKey?: string) {
    // Child properties are applied after the parent constructor (https://stackoverflow.com/a/58542908)
    setTimeout(() => {
      this.stateStorageKey = stateStorageKey; // set this before anything else to guarantee access to storage

      this._setupLoader();

      // initialize state; keep anything that was already set
      this._state = {
        ...this.getBaseState(),
        ...this._state,
      };
      this._state$.next(this._state);

      this._queryFilters$.next(this._state.filters);

      // initial page load getting the state ready for initFilters
      if (!this._loadStateParams()) {
        // force first load
        this._load$.next();
      }

      if (router) {
        let previousUrl: string = null;
        router.events.pipe(untilDestroyed(this)).subscribe((event) => {
          if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
            if (previousUrl !== event.url) {
              this._queryParamsAreReset = previousUrl && previousUrl.split('?')[0] === event.url;
              previousUrl = event.url;
              this._loadStateParams();
            }
          }
        });
      }
    }, 50);
  }

  protected _state$ = new BehaviorSubject<State>({});

  get state$() {
    return this._state$.asObservable();
  }

  protected _queryFilters$ = new BehaviorSubject<string>(null);

  get queryFilters$() {
    return this._queryFilters$.asObservable();
  }

  protected _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  protected _records$ = new BehaviorSubject<T[]>([]);

  get records$() {
    return this._records$.asObservable();
  }

  protected _total$ = new BehaviorSubject<number>(0);

  get total$() {
    return this._total$.asObservable();
  }

  get searchTerm() {
    return this._state.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    // Reset page when searching
    this._set({ searchTerm, page: 1 });
  }

  get withTrashed() {
    return this._state.withTrashed;
  }

  set withTrashed(withTrashed: boolean) {
    this._set({ withTrashed: withTrashed || null });
  }

  get filters() {
    return this._state.filters;
  }

  set filters(filters: string) {
    // Reset page when filtering
    this._set({ filters, page: 1 });
  }

  get page() {
    return this._state.page;
  }

  set page(page: number) {
    this._set({ page });
  }

  get first() {
    return ((this._state.page || 1) - 1) * (this._state.pageSize || 0);
  }

  get pageSize() {
    return this._state.pageSize;
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  get sortColumn() {
    return this._state.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }

  get sortDirection() {
    return this._state.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  get sortOrder() {
    return this._state.sortColumn ? (this._state.sortDirection === 'desc' ? -1 : 1) : null;
  }

  get fields() {
    return this._state.fields;
  }

  set fields(fields: string) {
    this._set({ fields: fields || null });
  }

  get relations() {
    return this._state.relations;
  }

  set relations(relations: string) {
    this._set({ relations: relations || null });
  }

  set lazyLoadEvent(event: LazyLoadEvent) {
    const state: State = {
      ...this._state,
      page: event.rows ? (event.first || 0) / event.rows + 1 : 1,
      pageSize: event.rows,
      sortColumn: event.sortField,
      sortDirection: event.sortField ? (event.sortOrder < 0 ? 'desc' : 'asc') : null,
    };

    if (event.filters) {
      const fields = Object.keys(event.filters);
      if (fields.length) {
        const filters: Filters = [];
        fields.forEach((field) => {
          filters.push({
            field,
            value: event.filters[field].value,
            operator: event.filters[field].matchMode as FilterOperator,
            // _boolean: event.filters[field].operator as FilterBoolean,
          });
        });
        state.filters = JSON.stringify(filters);
      }
    }

    this._set(state);
  }

  get storageState(): State {
    if (!this.stateStorageKey) {
      return {};
    }

    const storage = this.getStorage();
    if (storage) {
      return JSON.parse(storage.getItem(this.stateStorageKey)) || {};
    }

    return {};
  }

  set storageState(state: State) {
    if (!this.stateStorageKey) {
      return;
    }

    const storage = this.getStorage();
    if (storage) {
      storage.setItem(this.stateStorageKey, JSON.stringify(state));
    }
  }

  public reload() {
    this._load$.next();
  }

  getStorage() {
    if (platformId) {
      if (isPlatformBrowser(platformId)) {
        switch (this.stateStorage) {
          case 'local':
            return window.localStorage;

          case 'session':
            return window.sessionStorage;

          default:
            throw new Error(
              this.stateStorage +
                ' is not a valid value for the state storage, supported values are "local" and "session".',
            );
        }
      } else {
        throw new Error('Browser storage is not available in the server side.');
      }
    }
  }

  protected getBaseState(): State {
    return {
      searchTerm: '', // leave it empty string
      withTrashed: null,
      filters: null,
      page: 1,
      pageSize: 10,
      sortColumn: null,
      sortDirection: null,
      fields: null,
      relations: null,
    };
  }

  protected _getParam(paramKey: string) {
    const prefix = this._queryParamsPrefix ? this._queryParamsPrefix : '';

    const paramKeyWithPrefix = prefix + paramKey;

    let queryHasAnyStateParams = false;

    if (route && route.snapshot && route.snapshot.queryParams) {
      const value = route.snapshot.queryParams[paramKeyWithPrefix];
      if (value !== undefined) {
        return value;
      }

      for (const key in this.getBaseState()) {
        const keyWithPrefix = prefix + key;
        if (route.snapshot.queryParams[keyWithPrefix] !== undefined) {
          queryHasAnyStateParams = true;
        }
      }
    }

    if (!queryHasAnyStateParams && !this._queryParamsAreReset) {
      const storageState = this.storageState;
      if (storageState) {
        const value = storageState[paramKeyWithPrefix];
        if (value !== undefined) {
          return value;
        }
      }
    }

    return null;
  }

  protected _paramValueNotEmpty(paramValue: string) {
    return [null, undefined, ''].indexOf(paramValue) === -1;
  }

  protected _loadStateParams(changeState = true) {
    let paramValue: string;

    const baseState = this.getBaseState();

    let stateChanged = false;

    if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('searchTerm')))) {
      if (this._state.searchTerm !== paramValue) {
        this._state.searchTerm = paramValue;
        stateChanged = true;
      }
    } else if (this._state.searchTerm !== baseState.searchTerm) {
      this._state.searchTerm = baseState.searchTerm;
      stateChanged = true;
    }

    if (
      this._queryParams &&
      this._paramValueNotEmpty((paramValue = this._getParam('withTrashed'))) &&
      paramValue.toLowerCase() !== 'false'
    ) {
      if (this._state.withTrashed !== true) {
        this._state.withTrashed = true;
        stateChanged = true;
      }
    } else if (this._state.withTrashed !== baseState.withTrashed) {
      this._state.withTrashed = baseState.withTrashed;
      stateChanged = true;
    }

    if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('filters')))) {
      if (this._state.filters !== paramValue) {
        this._state.filters = paramValue;
        this._queryFilters$.next(this._state.filters);
        stateChanged = true;
      }
    } else if (this._state.filters !== baseState.filters) {
      this._state.filters = baseState.filters;
      this._queryFilters$.next(this._state.filters);
      stateChanged = true;
    }

    if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('page')))) {
      if (this._state.page !== parseInt(paramValue)) {
        this._state.page = parseInt(paramValue);
        stateChanged = true;
      }
    } else if (this._state.page !== baseState.page) {
      this._state.page = baseState.page;
      stateChanged = true;
    }
    if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('pageSize')))) {
      if (this._state.pageSize !== parseInt(paramValue)) {
        this._state.pageSize = parseInt(paramValue);
        stateChanged = true;
      }
    } else if (this._state.pageSize !== baseState.pageSize) {
      this._state.pageSize = baseState.pageSize;
      stateChanged = true;
    }

    if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('sortColumn')))) {
      if (this._state.sortColumn !== paramValue) {
        this._state.sortColumn = paramValue;
        stateChanged = true;
      }
    } else if (this._state.sortColumn !== baseState.sortColumn) {
      this._state.sortColumn = baseState.sortColumn;
      stateChanged = true;
    }
    if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('sortDirection')))) {
      if (this._state.sortDirection !== (paramValue as SortDirection)) {
        this._state.sortDirection = paramValue as SortDirection;
        stateChanged = true;
      }
    } else if (this._state.sortDirection !== baseState.sortDirection) {
      this._state.sortDirection = baseState.sortDirection;
      stateChanged = true;
    }

    if (this._queryParamsFields) {
      if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('fields')))) {
        if (this._state.fields !== paramValue) {
          this._state.fields = paramValue;
          stateChanged = true;
        }
      } else if (this._state.fields !== baseState.fields) {
        this._state.fields = baseState.fields;
        stateChanged = true;
      }
    }
    if (this._queryParamsRelations) {
      if (this._queryParams && this._paramValueNotEmpty((paramValue = this._getParam('relations')))) {
        if (this._state.relations !== paramValue) {
          this._state.relations = paramValue;
          stateChanged = true;
        }
      } else if (this._state.relations !== baseState.relations) {
        this._state.relations = baseState.relations;
        stateChanged = true;
      }
    }

    if (changeState && stateChanged) {
      this._load$.next();
      this._state$.next(this._state);
    }

    return stateChanged;
  }

  protected _addParam(params: any, paramKey: string, paramValue: string) {
    params[(this._queryParamsPrefix ? this._queryParamsPrefix : '') + paramKey] = paramValue;
  }

  protected _getParamsFromState() {
    const baseState = this.getBaseState();

    const params: { [key: string]: string } = {};

    this._addParam(
      params,
      'searchTerm',
      (this._state.searchTerm || null) !== (baseState.searchTerm || null) ? this._state.searchTerm : null,
    );

    this._addParam(
      params,
      'withTrashed',
      (this._state.withTrashed || null) !== (baseState.withTrashed || null) ? String(this._state.withTrashed) : null,
    );

    this._addParam(
      params,
      'filters',
      ((this._state.filters && (this._state.filters === '[]' || this._state.filters === '{}')
        ? null
        : this._state.filters) || null) !== (baseState.filters || null)
        ? this._state.filters
        : null,
    );

    // Skip default page
    this._addParam(
      params,
      'page',
      [null, undefined].indexOf(this._state.page) === -1 && this._state.page !== baseState.page
        ? String(this._state.page)
        : null,
    );
    // Skip default pageSize
    this._addParam(
      params,
      'pageSize',
      [null, undefined].indexOf(this._state.pageSize) === -1 && this._state.pageSize !== baseState.pageSize
        ? String(this._state.pageSize)
        : null,
    );

    this._addParam(
      params,
      'sortColumn',
      (this._state.sortColumn || null) !== (baseState.sortColumn || null) ? this._state.sortColumn : null,
    );
    this._addParam(
      params,
      'sortDirection',
      (this._state.sortDirection || null) !== (baseState.sortDirection || null) ? this._state.sortDirection : null,
    );

    if (this._queryParamsFields) {
      this._addParam(
        params,
        'fields',
        ((this._state.fields && (this._state.fields === '[]' || this._state.fields === '{}')
          ? null
          : this._state.fields) || null) !== (baseState.fields || null)
          ? this._state.fields
          : null,
      );
    }
    if (this._queryParamsRelations) {
      this._addParam(
        params,
        'relations',
        ((this._state.relations && (this._state.relations === '[]' || this._state.relations === '{}')
          ? null
          : this._state.relations) || null) !== (baseState.relations || null)
          ? this._state.relations
          : null,
      );
    }

    return params;
  }

  protected async setParams() {
    if (this._queryParams) {
      // Get the state params
      const paramsState = this._getParamsFromState();

      // Get all the other params
      const paramsOther: { [key: string]: string } = {};

      if (route && route.snapshot && route.snapshot.queryParams) {
        Object.keys(route.snapshot.queryParams).forEach((paramKey) => {
          if (paramsState[paramKey] === undefined) {
            paramsOther[paramKey] = route.snapshot.queryParams[paramKey];
          }
        });
      }

      this.storageState = paramsState;

      if (router && route) {
        await router.navigate([], {
          relativeTo: route,
          queryParams: {
            // other before state
            ...paramsOther,
            ...paramsState,
          },
          replaceUrl: this._queryParamsReplaceUrl,
        });
      }
    }
  }

  protected _setupLoader() {
    const loaded = () => this._loading$.next(false);

    this._load$
      .pipe(
        untilDestroyed(this),
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => {
          this.setParams().then();

          return this._search();
        }),
        tap(loaded),
      )
      .subscribe(
        (result) => {
          this._records$.next(result.data);
          this._total$.next(result.meta ? result.meta.total : null);
        },
        () => {
          loaded();
          this._setupLoader(); // for some reason the pipe and subscriptions are broken on error so we need to rebuild them
        },
        loaded,
      );
  }

  protected _set(patch: Partial<State>) {
    const state: State = {};
    Object.keys(patch).forEach((key) => (state[key] = this._state[key]));
    if (JSON.stringify(state) !== JSON.stringify(patch)) {
      Object.assign(this._state, patch);
      this._load$.next();
      this._state$.next(this._state);
    }
  }

  protected _search(): Observable<RecordsList<T>> {
    return of({ data: [], total: 0 });
  }
}
