<ul
  #menubar
  (blur)="menuBlur.emit($event)"
  (focus)="menuFocus.emit($event)"
  (keydown)="menuKeydown.emit($event)"
  [attr.aria-activedescendant]="focusedItemId"
  [attr.aria-label]="ariaLabel"
  [attr.aria-labelledBy]="ariaLabelledBy"
  [attr.data-pc-section]="'menu'"
  [attr.id]="root ? menuId : null"
  [ngClass]="{ 'p-submenu-list': !root, 'p-menubar-root-list': root }"
  [tabindex]="0"
  role="menubar"
>
  <ng-template [ngForOf]="items" let-index="index" let-processedItem ngFor>
    <li
      *ngIf="isItemVisible(processedItem) && getItemProp(processedItem, 'separator')"
      [attr.data-pc-section]="'separator'"
      [attr.id]="getItemId(processedItem)"
      [ngClass]="getSeparatorItemClass(processedItem)"
      [ngStyle]="getItemProp(processedItem, 'style')"
      role="separator"
    ></li>
    <li
      #listItem
      *ngIf="isItemVisible(processedItem) && !getItemProp(processedItem, 'separator')"
      [attr.aria-disabled]="isItemDisabled(processedItem) || undefined"
      [attr.aria-expanded]="isItemGroup(processedItem) ? isItemActive(processedItem) : undefined"
      [attr.aria-haspopup]="isItemGroup(processedItem) && !getItemProp(processedItem, 'to') ? 'menu' : undefined"
      [attr.aria-label]="getItemLabel(processedItem)"
      [attr.aria-level]="level + 1"
      [attr.aria-posinset]="getAriaPosInset(index)"
      [attr.aria-setsize]="getAriaSetSize()"
      [attr.data-p-disabled]="isItemDisabled(processedItem)"
      [attr.data-p-focused]="isItemFocused(processedItem)"
      [attr.data-p-highlight]="isItemActive(processedItem)"
      [attr.data-pc-section]="'menuitem'"
      [attr.id]="getItemId(processedItem)"
      [class]="getItemProp(processedItem, 'styleClass')"
      [ngClass]="getItemClass(processedItem)"
      [ngStyle]="getItemProp(processedItem, 'style')"
      [tooltipOptions]="getItemProp(processedItem, 'tooltipOptions')"
      pTooltip
      role="menuitem"
    >
      <div
        (click)="onItemClick($event, processedItem)"
        (mouseenter)="onItemMouseEnter({ $event, processedItem })"
        [attr.data-pc-section]="'content'"
        class="p-menuitem-content"
      >
        <ng-container *ngIf="!itemTemplate">
          <a
            *ngIf="!getItemProp(processedItem, 'routerLink')"
            [attr.data-automationid]="getItemProp(processedItem, 'automationId')"
            [attr.data-pc-section]="'action'"
            [attr.href]="getItemProp(processedItem, 'url')"
            [attr.tabindex]="-1"
            [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': getItemProp(processedItem, 'disabled') }"
            [target]="getItemProp(processedItem, 'target')"
            pRipple
          >
            <span
              *ngIf="getItemProp(processedItem, 'icon')"
              [attr.data-pc-section]="'icon'"
              [attr.tabindex]="-1"
              [ngClass]="getItemProp(processedItem, 'icon')"
              [ngStyle]="getItemProp(processedItem, 'iconStyle')"
              class="p-menuitem-icon"
            ></span>
            <span
              *ngIf="getItemProp(processedItem, 'escape'); else htmlLabel"
              [attr.data-pc-section]="'label'"
              [id]="getItemLabelId(processedItem)"
              class="p-menuitem-text"
            >
              {{ getItemLabel(processedItem) }}
            </span>
            <ng-template #htmlLabel>
              <span
                [attr.data-pc-section]="'label'"
                [id]="getItemLabelId(processedItem)"
                [innerHTML]="getItemLabel(processedItem)"
                class="p-menuitem-text"
              ></span>
            </ng-template>
            <span
              *ngIf="getItemProp(processedItem, 'badge')"
              [ngClass]="getItemProp(processedItem, 'badgeStyleClass')"
              class="p-menuitem-badge"
            >
              {{ getItemProp(processedItem, 'badge') }}
            </span>

            <ng-container *ngIf="isItemGroup(processedItem)">
              <ng-container *ngIf="!submenuIconTemplate">
                <AngleDownIcon *ngIf="root" [attr.data-pc-section]="'submenuicon'" [styleClass]="'p-submenu-icon'" />
                <AngleRightIcon *ngIf="!root" [attr.data-pc-section]="'submenuicon'" [styleClass]="'p-submenu-icon'" />
              </ng-container>
              <ng-template *ngTemplateOutlet="submenuIconTemplate" [attr.data-pc-section]="'submenuicon'"></ng-template>
            </ng-container>
          </a>
          <a
            *ngIf="getItemProp(processedItem, 'routerLink')"
            [attr.data-automationid]="getItemProp(processedItem, 'automationId')"
            [attr.data-pc-section]="'action'"
            [attr.tabindex]="-1"
            [fragment]="getItemProp(processedItem, 'fragment')"
            [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': getItemProp(processedItem, 'disabled') }"
            [preserveFragment]="getItemProp(processedItem, 'preserveFragment')"
            [queryParamsHandling]="getItemProp(processedItem, 'queryParamsHandling')"
            [queryParams]="getItemProp(processedItem, 'queryParams')"
            [replaceUrl]="getItemProp(processedItem, 'replaceUrl')"
            [routerLinkActiveOptions]="getItemProp(processedItem, 'routerLinkActiveOptions') || { exact: false }"
            [routerLinkActive]="'p-menuitem-link-active'"
            [routerLink]="getItemProp(processedItem, 'routerLink')"
            [skipLocationChange]="getItemProp(processedItem, 'skipLocationChange')"
            [state]="getItemProp(processedItem, 'state')"
            [target]="getItemProp(processedItem, 'target')"
            pRipple
          >
            <span
              *ngIf="getItemProp(processedItem, 'icon')"
              [attr.data-pc-section]="'icon'"
              [attr.tabindex]="-1"
              [ngClass]="getItemProp(processedItem, 'icon')"
              [ngStyle]="getItemProp(processedItem, 'iconStyle')"
              class="p-menuitem-icon"
            ></span>
            <span *ngIf="getItemProp(processedItem, 'escape'); else htmlRouteLabel" class="p-menuitem-text">
              {{ getItemLabel(processedItem) }}
            </span>
            <ng-template #htmlRouteLabel>
              <span
                [attr.data-pc-section]="'label'"
                [innerHTML]="getItemLabel(processedItem)"
                class="p-menuitem-text"
              ></span>
            </ng-template>
            <span
              *ngIf="getItemProp(processedItem, 'badge')"
              [ngClass]="getItemProp(processedItem, 'badgeStyleClass')"
              class="p-menuitem-badge"
            >
              {{ getItemProp(processedItem, 'badge') }}
            </span>
            <ng-container *ngIf="isItemGroup(processedItem)">
              <ng-container *ngIf="!menubar.submenuIconTemplate">
                <AngleDownIcon *ngIf="root" [attr.data-pc-section]="'submenuicon'" [styleClass]="'p-submenu-icon'" />
                <AngleRightIcon *ngIf="!root" [attr.data-pc-section]="'submenuicon'" [styleClass]="'p-submenu-icon'" />
              </ng-container>
              <ng-template
                *ngTemplateOutlet="menubar.submenuIconTemplate"
                [attr.data-pc-section]="'submenuicon'"
              ></ng-template>
            </ng-container>
          </a>
        </ng-container>
        <ng-container *ngIf="itemTemplate">
          <ng-template
            *ngTemplateOutlet="itemTemplate; context: { $implicit: processedItem.item, root: root }"
          ></ng-template>
        </ng-container>
      </div>
      <c-menubarSub
        (itemClick)="itemClick.emit($event)"
        (itemMouseEnter)="onItemMouseEnter($event)"
        *ngIf="isItemVisible(processedItem) && isItemGroup(processedItem)"
        [activeItemPath]="activeItemPath"
        [ariaLabelledBy]="getItemLabelId(processedItem)"
        [autoDisplay]="autoDisplay"
        [focusedItemId]="focusedItemId"
        [itemTemplate]="itemTemplate"
        [items]="processedItem.items"
        [level]="level + 1"
        [menuId]="menuId"
        [mobileActive]="mobileActive"
      ></c-menubarSub>
    </li>
  </ng-template>
</ul>
