import { Data, Route, Routes } from '@angular/router';

import { ShellComponent } from './shell.component';
import { ActivationGuard, AuthenticationGuard, ChildActivationGuard, DeactivationGuard, LoadGuard } from '@core';

/**
 * Provides helper methods to create routes.
 */
export class Shell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @param data
   * @param authenticationGuard
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes, data: Data = null, authenticationGuard = true): Route {
    const guards: any[] = [ActivationGuard];
    if (authenticationGuard) {
      guards.push(AuthenticationGuard);
    }
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: guards,
      canActivateChild: [ChildActivationGuard],
      canDeactivate: [DeactivationGuard],
      canLoad: [LoadGuard],
      data,
    };
  }
}
